import { ColumnsRepeaterProps } from '@wix/thunderbolt-components'
import style from './style/ColumnsRepeater.scss'
import React, { ComponentType, ReactNode, MouseEventHandler } from 'react'
import _ from 'lodash'

export type ColumnsRepeaterCompProps = ColumnsRepeaterProps & {
	id: string
	children: (id: string) => ReactNode
}

const ColumnsRepeater: ComponentType<ColumnsRepeaterCompProps> = ({
	id,
	children,
	items,
	columns,
	onMouseEnter = _.noop,
	onMouseLeave = _.noop,
}) => {
	const itemsInColumn = items.reduce<Array<Array<string>>>(
		(acc, item, index) => {
			const column = index % columns
			acc[column].push(item)
			return acc
		},
		new Array(columns).fill(null).map(() => [])
	)

	return (
		<div id={id} onMouseEnter={onMouseEnter as MouseEventHandler} onMouseLeave={onMouseLeave as MouseEventHandler}>
			<div role="grid" className={style.grid}>
				{itemsInColumn.map((column, idx) => (
					<div role="row" className={style.row} key={idx}>
						{column.map((childId) => (
							<div role="gridcell" className={style.gridcell} key={childId}>
								{children(childId)}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	)
}

export default ColumnsRepeater
